import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';

import Pusher from 'pusher-js'
Pusher.logToConsole = true;
// import PusherPlugin from './plugins/pusher.js';
// const apiKey = 'd9f14c2b1529677d5cab';

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

window.pusher = new Pusher('d9f14c2b1529677d5cab', {
    cluster: 'mt1'
})

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(pinia)
            // .use(PusherPlugin, { apiKey, cluster: 'mt1' })
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
